@font-face {
    font-family: "The Next";
    src: url("../fonts/the-next/TheNextFont.ttf") format("truetype");
}

@font-face {
    font-family: "DIN Condensed";
    src: url("../fonts/din-condensed/DINCondensed-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Futura PT Bold";
    src: url("../fonts/futura-pt/FuturaPTBold.otf") format("opentype");
}

@font-face {
    font-family: "Futura PT Demi";
    src: url("../fonts/futura-pt/FuturaPTDemi.otf") format("opentype");
}

@font-face {
    font-family: "Futura PT Medium";
    src: url("../fonts/futura-pt/FuturaPTMedium.otf") format("opentype");
}

@font-face {
    font-family: "Futura PT Book";
    src: url("../fonts/futura-pt/FuturaPTBook.otf") format("opentype");
}


@font-face {
    font-family: "New Hero Thin";
    src: url("../fonts/new-hero/Hero-New-Thin.otf") format("opentype");
}

@font-face {
    font-family: "New Hero Light";
    src: url("../fonts/new-hero/Hero-New-Light.otf") format("opentype");
}


@font-face {
    font-family: "New Hero Regular";
    src: url("../fonts/new-hero/Hero-New-Regular.otf") format("opentype");
}


@font-face {
    font-family: "New Hero Medium";
    src: url("../fonts/new-hero/Hero-New-Medium.otf") format("opentype");
}

@font-face {
    font-family: "New Hero Bold";
    src: url("../fonts/new-hero/Hero-New-Bold.otf") format("opentype");
}


.ril__outer {
    background-color: rgba(255, 255, 255,.85) !important;
}

.ril__navButtonNext {
    background-color: rgba(0, 0, 0,.7) !important;
}

.ril__navButtonPrev {
    background-color: rgba(0, 0, 0,.7) !important;
}

.ril-next-button {
    color: black !important;
    opacity: 1 !important;
}

.ril-prev-button {
    opacity: 1 !important;
}

.ril__navButtons {
    opacity: 1 !important;
}

.remove-p-margin p {
    margin: 0 !important;
}


.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > img { /* change div to reference your elements you put in <Masonry> */
    /* max-width: 300px; */
  }

.no-selection {
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}